import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

TrafficLast5Min.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  currentLinkid: PropTypes.string.isRequired,
  serverAddr: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default function TrafficLast5Min({ title, subheader, currentLinkid, serverAddr, authToken, ...other }) {
  const [ last5MinData, setLast5MinData ] = useState([]);
  const timeoutRef = useRef(10); // use 10 seconds timeout
  const chartLabels=['Gol-1', 'Gol-2', 'Gol-3', 'Gol-4', 'Gol-5A', 'Gol-5B',
                'Gol-6A', 'Gol-6B', 'Gol-7A', 'Gol-7B', 'Gol-7C', 'Gol-8',];
  // const surveyResultUrl = `http://${serverAddr}/api/survey_results/`;
  const surveyResultUrl = `${serverAddr}/api/survey_results_last_5min/`;

  const updateLast5MinData = useCallback((dataArr) => {
        console.log('TrafficLast5Min() -> updateLast5MinData callback ');
        setLast5MinData([]);
        if (dataArr.length > 0){ 
            for (let i = 0; i < dataArr.length; i += 1) {
                setLast5MinData(l => ([...l, {
                              name: dataArr[i].survey_direc,
                              type: 'bar',
                              fill: 'solid',
                              data: [dataArr[i].num_veh1,  dataArr[i].num_veh2,
                                     dataArr[i].num_veh3,  dataArr[i].num_veh4, 
                                     dataArr[i].num_veh5a, dataArr[i].num_veh5b, 
                                     dataArr[i].num_veh6a, dataArr[i].num_veh6b, 
                                     dataArr[i].num_veh7a, dataArr[i].num_veh7b, 
                                     dataArr[i].num_veh7c, dataArr[i].num_veh8]
                            }]));
            }
        }
        else {
            setLast5MinData(l => ([...l, {
                            name: "",
                            type: 'bar',
                            fill: 'solid',
                            data: [0, 0, 0, 0, 0, 0, 
                                   0, 0, 0, 0, 0, 0]
                            }]));
        }
    }, []);


  const processLast5MinData = useCallback((currentLinkid, surveyResultUrl, authToken) => {
        axios.get(surveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('TrafficLast5Min() -> useCallback() GET response stats: ', res.data);
                updateLast5MinData(res.data);
                // console.log('TrafficLast5Min() useCallback() -> last5MinData: ', last5MinData);
           })
           .catch( err => {
               console.log('TrafficLast5Min() -> useCallback(): ERROR: ', err.message);
           });

        }, [updateLast5MinData]); 

  useEffect(() => {

        console.log("TrafficLast5Min() => useEffect(): currentLinkid = ", currentLinkid);
        processLast5MinData(currentLinkid, surveyResultUrl, authToken);

        /* update based on timeout setting */
        const intervalSec = 10;
        const today = new Date();
        timeoutRef.current = intervalSec - (today.getSeconds() % intervalSec);

        const interval = setInterval(() => {

            processLast5MinData(currentLinkid, surveyResultUrl, authToken);

        }, 1000 * intervalSec); 

        return () => clearInterval(interval);

  }, [currentLinkid, processLast5MinData, surveyResultUrl, authToken]);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '85%' } },
    fill: { type: last5MinData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'categories' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} kendaraan`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 2, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={last5MinData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
