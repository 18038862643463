import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { 
    Box, 
    Stack, 
    AppBar, 
    Toolbar, 
    IconButton, 
    Typography, 
    Container,
    Grid 
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Logo from '../../../components/logo';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';


// ----------------------------------------------------------------------

// const NAV_WIDTH = 280;
const NAV_WIDTH = 0;

const HEADER_MOBILE = 24;

const HEADER_DESKTOP = 48;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 2),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>

        { /* 
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
            <Searchbar />
            <Box sx={{ flexGrow: 1 }} />
          */ }

        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 1,
              sm: 1,
            }}
            >   
                <Box sx={{ flexGrow: 1, p: 1, pb: 1 }} dir="ltr">
                    {/* PADDING  */}
                    <Typography variant="h5" color="#1c54b2" align="left" noWrap />
                </Box>
                <Logo />
                <Box sx={{ flexGrow: 1, p: 1, pb: 1 }} dir="ltr">
                    <Typography variant="h5" color="#1c54b2" align="left" noWrap>
                        KEMENTERIAN PEKERJAAN UMUM <br />DAN PERUMAHAN RAKYAT
                    </Typography>
                </Box>
            </Stack>
          </Grid>
          { /*
          <Grid item xs={1} md={1} lg={1}>
            <Typography variant="h5" color="#1c54b2" align="center" noWrap/>
          </Grid> */ }
          <Grid item xs={12} md={6} lg={6}>
            <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 1,
              sm: 1,
            }}
            >
                <Box sx={{ flexGrow: 1, p: 1, pb: 1 }} dir="ltr">
                    {/* PADDING  */}
                    <Typography variant="h5" color="#1c54b2" align="left" noWrap />
                </Box>
                <Box sx={{ flexGrow: 1, p: 1, pb: 1 }} dir="ltr">
                    <Typography variant="h5" color="#1c54b2" align="left" noWrap>
                        LALU LINTAS OTOMATIS TERKLASIFIKASI 
                    </Typography>
                    <Typography variant="normal" color="#1c54b2" align="left" noWrap>
                        Berbasis <i>Artificial Intelligence</i> 
                    </Typography>
                </Box>
            </Stack>
          </Grid>
        </Grid>

        { /*
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >          
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
          */ }
      </StyledToolbar>
    </StyledRoot>
  );
}
