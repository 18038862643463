import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import MenuIcon from "@mui/icons-material/Menu";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';


const actions = [
  { icon: <MapTwoToneIcon />, name: 'Peta Survey' },
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

export default function BasicSpeedDial() {
  return (
    <div>
      <SpeedDial
        ariaLabel="Speed Dial"
        sx={{ position: 'fixed', bottom: 20, right: 20 }}
        icon={<MenuIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
