import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
// @mui
import { 
    MenuItem, 
    // InputLabel,
    FormControlLabel,
    // FormControl, 
    Select,    
    // Typography, 
    // Grid, 
    Card, 
    CardHeader, 
    Box 
} from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

TrafficComparison.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  currentLinkid: PropTypes.string.isRequired,
  serverAddr: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,

//  chartData: PropTypes.array.isRequired,
//  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function TrafficComparison({ title, subheader, currentLinkid, serverAddr, authToken, ...other }) {
    const [ allChartData, setAllChartData ] = useState([]);
    const [ arah, setArah ] = useState('Semua');
    const [ tipeKend, setTipeKend ] = useState('Semua');
    const [ normal, setNormal ] = useState('Normal');
    const [ opposite, setOpposite] = useState('Opposite');
    const chartLabels=[ '00-01', '01-02', '02-03', '03-04', '04-05', '05-06', 
                        '06-07', '07-08', '08-09', '09-10', '10-11', '11-12',
                        '12-13', '13-14', '14-15', '15-16', '16-17', '17-18',
                        '18-19', '19-20', '20-21', '21-22', '22-23', '23-24', ];

    const hourlySurveyResultUrl = `${serverAddr}/api/survey_results_last_hour/`;
    const prevDaySurveyResultUrl = `${serverAddr}/api/survey_results_prev_day/`;
    const last7DaySurveyResultUrl = `${serverAddr}/api/survey_results_last_7day/`;

    const showAllRealData = useCallback((currentLinkid, authToken, last7DayData, chartData, arah, tipeKend) => {

            if (last7DayData.length > 0) {

                const last7DayTotalCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0];
                const last7DayAvg =  {
                                     name: `Rata-rata 7 Hari Terakhir (${tipeKend})`,
                                     type: 'line',
                                     fill: 'fill',
                                     data: [0, 0, 0, 0, 0, 0, 0, 0, 
                                        0, 0, 0, 0, 0, 0, 0, 0, 
                                        0, 0, 0, 0, 0, 0, 0, 0] 
                                   };

                for (let i = 0; i < last7DayData.length; i += 1) {
                    // Remove zone indicator to prevent timezone conversion by the browser
                    const [dateStr, zone] = last7DayData[i].datetime_utc.split('Z');
                    const itemDate = new Date(dateStr);
                    const itemHour = itemDate.getHours();
                    
                    for (let j = 0; j < 24; j += 1) {
                        if (itemHour === j) {

                            switch (tipeKend) {
                                case 'Sepeda Motor':
                                    last7DayTotalCounts[j] += last7DayData[i].num_veh1;
                                    break; 
                                case 'Mobil Penumpang':
                                    last7DayTotalCounts[j] += (last7DayData[i].num_veh2 + last7DayData[i].num_veh3 + last7DayData[i].num_veh4);
                                    break; 
                                case 'Kendaraan Sedang':
                                    last7DayTotalCounts[j] += last7DayData[i].num_veh5a;
                                    break;
                                 case 'Bus Besar':
                                    last7DayTotalCounts[j] += (last7DayData[i].num_veh5b + last7DayData[i].num_veh6a + last7DayData[i].num_veh6b);
                                    break;
                                 case 'Truk Besar':
                                    last7DayTotalCounts[j] += (last7DayData[i].num_veh7a + last7DayData[i].num_veh7b + last7DayData[i].num_veh7c);
                                    break;
                                default:
                                    last7DayTotalCounts[j] += (last7DayData[i].num_veh1 + last7DayData[i].num_veh2 + last7DayData[i].num_veh3 + 
                                                        last7DayData[i].num_veh4 + last7DayData[i].num_veh5a + last7DayData[i].num_veh5b + 
                                                        last7DayData[i].num_veh6a + last7DayData[i].num_veh6b + last7DayData[i].num_veh7a + 
                                                        last7DayData[i].num_veh7b + last7DayData[i].num_veh7c);
                            }
                        }
                    }
                }
               
                // const allChartData = [];
                // for (let i = 0; i < chartData.length; i += 1) {
                //     allChartData.push(chartData[i]);
                // }
                const allChartData = chartData;

                /* Carry-out 7 day average calculation */
                for (let i = 0; i < last7DayTotalCounts.length; i += 1) {
                    last7DayAvg.data[i] = Math.floor(last7DayTotalCounts[i] / 7);
                }
                allChartData.push(last7DayAvg);


                console.log("showAllRealData() -> useCallback(): allChartData = ", allChartData);
    
                setAllChartData(allChartData);
            
            } else {
                console.log('showAllRealData() -> useCallback(): last7DayData.length is 0');
                setAllChartData(chartData);
            } 

        }, []);

    const append7DayAvgData = useCallback((currentLinkid, last7DaySurveyResultUrl, authToken, chartData, arah, tipeKend) => {
        axios.get(last7DaySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('TrafficComparison() append7DayAvgData() -> GET response stats: ', res.data);
                console.log('TrafficComparison() append7DayAvgData() -> chartData', chartData);
                showAllRealData(currentLinkid, authToken, res.data, chartData, arah, tipeKend);
           })
           .catch( err => {
               console.log('TrafficComparison() append7DayAvgData() -> ERROR: ', err.message);
               setAllChartData(chartData); // Show previous call stack data - fail gracefully
           });

        }, [showAllRealData]); 

    const preparePrevDayData = useCallback((currentLinkid, authToken, prevDayData, todayChartData, arah, tipeKend) => {
            if (prevDayData.length > 0) {
                console.log('preparePrevDayData() -> useCallback(): todayChartData', todayChartData);
                const prevDayTotalCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0];

                for (let i = 0; i < prevDayData.length; i += 1) {
                    // Remove zone indicator to prevent timezone conversion by the browser
                    const [dateStr, zone] = prevDayData[i].datetime_utc.split('Z');
                    const itemDate = new Date(dateStr);
                    const itemHour = itemDate.getHours();
                    
                    for (let j = 0; j < 24; j += 1) {
                        if (itemHour === j) {

                            switch (tipeKend) {
                                case 'Sepeda Motor':
                                    prevDayTotalCounts[j] += prevDayData[i].num_veh1;
                                    break; 
                                case 'Mobil Penumpang':
                                    prevDayTotalCounts[j] += (prevDayData[i].num_veh2 + prevDayData[i].num_veh3 + prevDayData[i].num_veh4);
                                    break; 
                                case 'Kendaraan Sedang':
                                    prevDayTotalCounts[j] += prevDayData[i].num_veh5a;
                                    break;
                                 case 'Bus Besar':
                                    prevDayTotalCounts[j] += (prevDayData[i].num_veh5b + prevDayData[i].num_veh6a + prevDayData[i].num_veh6b);
                                    break;
                                 case 'Truk Besar':
                                    prevDayTotalCounts[j] += (prevDayData[i].num_veh7a + prevDayData[i].num_veh7b + prevDayData[i].num_veh7c);
                                    break;
                                default:
                                    prevDayTotalCounts[j] += (prevDayData[i].num_veh1 + prevDayData[i].num_veh2 + prevDayData[i].num_veh3 + 
                                                        prevDayData[i].num_veh4 + prevDayData[i].num_veh5a + prevDayData[i].num_veh5b + 
                                                        prevDayData[i].num_veh6a + prevDayData[i].num_veh6b + prevDayData[i].num_veh7a + 
                                                        prevDayData[i].num_veh7b + prevDayData[i].num_veh7c);
                            }
                        }
                    }
                }
                
                const prevDayTotal = {
                                        name: `Kemarin (${tipeKend})`,
                                        type: 'line',
                                        fill: 'fill',
                                        data: prevDayTotalCounts 
                                      };
 
                const appendedChartData = [];
                const todayTotal =  {
                                     name: `Hari Ini (${tipeKend})`,
                                     type: 'bar',
                                     fill: 'solid',
                                     data: [0, 0, 0, 0, 0, 0, 0, 0, 
                                        0, 0, 0, 0, 0, 0, 0, 0, 
                                        0, 0, 0, 0, 0, 0, 0, 0] 
                                   };

                if (tipeKend === 'Semua') {
                    for (let j = 0; j < 24; j += 1) {
                        for (let k = 0; k < todayChartData.length; k += 1) {
                            todayTotal.data[j] += todayChartData[k].data[j];
                        }
                    }
                    appendedChartData.push(todayTotal);

                } else {
                    for (let i = 0; i < todayChartData.length; i+= 1) {
                        if (tipeKend === todayChartData[i].name) {
                            for (let j = 0; j < 24; j += 1) {
                                todayTotal.data[j] += todayChartData[i].data[j];
                            }
                            appendedChartData.push(todayTotal);
                            break;
                        }
                    }
                }
                appendedChartData.push(prevDayTotal);
                console.log("preparePrevDayData() -> useCallback(): appendedChartData = ", appendedChartData);
    
                append7DayAvgData(currentLinkid, last7DaySurveyResultUrl, authToken, appendedChartData, arah, tipeKend);

            } else {
                console.log('preparePrevDayData() -> useCallback(): prevDayData.length is 0');
                setAllChartData(todayChartData);
            }
        }, [append7DayAvgData, last7DaySurveyResultUrl]);

    const appendPrevDayData = useCallback((currentLinkid, prevDaySurveyResultUrl, authToken, chartData, arah, tipeKend) => {
        axios.get(prevDaySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('appendPrevDayData() -> useCallback() GET response stats: ', res.data);
                console.log('appendPrevDayData() -> useCallback(): chartData', chartData);
                preparePrevDayData(currentLinkid, authToken, res.data, chartData, arah, tipeKend);
           })
           .catch( err => {
                console.log('appendPrevDayData() -> useCallback(): ERROR: ', err.message);
                setAllChartData(chartData); // Fail gracefully by showing previous call-stack data
           });

        }, [preparePrevDayData]); 


    const prepareTodayData = useCallback((currentLinkid, authToken, arah, tipeKend, dataArr) => {
        console.log('TrafficComparison() -> prepareTodayData callback ');
        // setAllChartData([]);
        const sepedaMotorCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const mobilPenumpangCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const kendSedangCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const busBesarCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const trukBesarCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 

        if (dataArr.length > 0){
            console.log('TrafficComparison() -> prepareTodayData callback. Data length = ', dataArr.length);
            for (let i = 0; i < dataArr.length; i += 1) {
                // Remove zone indicator to prevent timezone conversion by the browser
                const [dateStr, zone] = dataArr[i].datetime_utc.split('Z'); 
                const itemDate = new Date(dateStr);
                const itemHour = itemDate.getHours();
                
                for (let j = 0; j < 24; j += 1) {
                    if (itemHour === j) {
                        sepedaMotorCounts[j] += dataArr[i].num_veh1;
                        mobilPenumpangCounts[j] += (dataArr[i].num_veh2 + dataArr[i].num_veh3 + dataArr[i].num_veh4);
                        kendSedangCounts[j] += dataArr[i].num_veh5a; 
                        busBesarCounts[j] += (dataArr[i].num_veh5b + dataArr[i].num_veh6a + dataArr[i].num_veh6b) ;
                        trukBesarCounts[j] += (dataArr[i].num_veh7a + dataArr[i].num_veh7b + dataArr[i].num_veh7c);
                    } 
                }
                
                console.log('DEBUG: TrafficComparison() -> prepareTodayData callback dataArr[', i, '] = ', dataArr[i]);
                console.log('DEBUG: TrafficComparison() -> prepareTodayData callback itemDate  = ', itemDate);
                console.log('DEBUG: TrafficComparison() -> prepareTodayData callback dateStr = ', dateStr);
            }
            const todayChartData = [{
                                      name: 'Sepeda Motor',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: sepedaMotorCounts 
                                    }, {
                                      name: 'Mobil Penumpang',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: mobilPenumpangCounts 
                                    },
                                    {
                                      name: 'Kendaraan Sedang',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: kendSedangCounts 
                                    }, {
                                      name: 'Bus Besar',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: busBesarCounts 
                                    }, {
                                      name: 'Truk Besar',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: trukBesarCounts 
                                    }];
            /* Proceed down the call stack to append the next data required by the chart  */
            appendPrevDayData(currentLinkid, prevDaySurveyResultUrl, authToken, todayChartData, arah, tipeKend);
            
        } else {
            setAllChartData([{
                              name: 'Sepeda Motor',
                              type: 'bar',
                              fill: 'solid',
                              data: sepedaMotorCounts 
                            }, {
                              name: 'Mobil Penumpang',
                              type: 'bar',
                              fill: 'solid',
                              data: mobilPenumpangCounts 
                            },
                            {
                              name: 'Kendaraan Sedang',
                              type: 'bar',
                              fill: 'solid',
                              data: kendSedangCounts 
                            }, {
                              name: 'Bus Besar',
                              type: 'bar',
                              fill: 'solid',
                              data: busBesarCounts 
                            }, {
                              name: 'Truk Besar',
                              type: 'bar',
                              fill: 'solid',
                              data: trukBesarCounts 
                            } , {
                              name: 'Total Hari Kemarin',
                              type: 'line',
                              fill: 'fill',
                              data: [80, 90, 79, 79, 98, 96, 94, 96, 
                                    96, 96, 97, 13, 14, 97, 57, 57, 
                                    57, 65, 46, 47, 49, 51, 53]
                            }, {
                              name: 'Rata-rata Pekan Lalu',
                              type: 'line',
                              fill: 'fill',
                              data: [14, 25, 25, 46, 51, 71, 61, 51, 
                                     41, 42, 52, 62, 82, 92, 43, 93, 
                                     93, 83, 64, 74, 74, 84, 55, 45]
                            }]);

            console.log('TrafficComparison() -> prepareTodayData callback. Data length is 0');
        }
    }, [appendPrevDayData, prevDaySurveyResultUrl]);

  const processHistoricalData = useCallback((currentLinkid, hourlySurveyResultUrl, authToken, arah, tipeKend) => {
        /* Get hourly data starting from midnight in the currentLinkid survey location   */
        axios.get(hourlySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('TrafficComparison() -> useCallback() GET response stats: ', res.data);
                prepareTodayData(currentLinkid, authToken, arah, tipeKend, res.data);
                // console.log('TrafficComparison() useCallback() -> allChartData: ', allChartData);
           })
           .catch( err => {
               console.log('TrafficComparison() -> useCallback(): ERROR: ', err.message);
           });

        }, [prepareTodayData]); 

  useEffect(() => {
    console.log('TrafficComparison() -> useEffect()');
    // TODO: 
    // update normal and opposite value based on location survey direction here!!!
    // get the value from the locations end point in the backend database by using currentLinkid
    processHistoricalData(currentLinkid, hourlySurveyResultUrl, authToken, arah, tipeKend);
    console.log("TrafficComparison() -> useEffect(): arah = ", arah);
    console.log("TrafficComparison() -> useEffect(): tipeKend = ", tipeKend);
  }, [currentLinkid, hourlySurveyResultUrl, authToken, processHistoricalData, arah, tipeKend]);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '80%' } },
    /* fill: { type: chartData.map((i) => i.fill) }, */
    fill: { type: allChartData.map((i) => i.fill) },
    labels: chartLabels,
    dataLabels: {
                enabled: true,
                enabledOnSeries: [2]
              },
    xaxis: { type: 'categories' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} kendaraan`;
          }
          return y;
        },
      },
    },
  });
    
    const handleArahChange = (event) => { 
            setArah(event.target.value); 
            console.log("handleArahChange(): new value = ", event.target.value);
        };
    
    const handleTipeKendChange = (event) => { 
            setTipeKend(event.target.value); 
            console.log("handleTipeKendChange(): new value = ", event.target.value);
        };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 2, pb: 1 }} dir="ltr">
           <FormControlLabel
                label="Arah: "
                labelPlacement="start"
                control={            
                    <Select
                      labelId="arah-label"
                      id="arah-select"
                      value={arah}
                      onChange={handleArahChange}
                    >
                        <MenuItem value={'Semua'}>Semua</MenuItem>
                        <MenuItem value={normal}>{normal}</MenuItem>
                        <MenuItem value={opposite}>{opposite}</MenuItem>
                    </Select>
                }
            />

           <FormControlLabel
                label="Kelas Kendaraan: "
                labelPlacement="start"
                control={            
                    <Select
                      labelId="tipe-kend-label"
                      id="tipe-kend-select"
                      value={tipeKend}
                      onChange={handleTipeKendChange}
                    >
                        <MenuItem value={'Semua'}>Semua</MenuItem>
                        <MenuItem value={'Sepeda Motor'}>Sepeda Motor</MenuItem>
                        <MenuItem value={'Mobil Penumpang'}>Mobil Penumpang</MenuItem>
                        <MenuItem value={'Kendaraan Sedang'}>Kendaraan Sedang</MenuItem>
                        <MenuItem value={'Bus Besar'}>Bus Besar</MenuItem>
                        <MenuItem value={'Truk Besar'}>Truk Besar</MenuItem>
                    </Select>
                    }
            />

            <ReactApexChart type="line" series={allChartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
