import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

// @mui
import { Grid, Card, CardHeader, Typography, Divider } from '@mui/material';
  
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

TrafficSpeed.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  currentLinkid: PropTypes.string.isRequired,
  serverAddr: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default function TrafficSpeed({ title, subheader, currentLinkid, serverAddr, authToken, ...other }) {
    const [ perMinuteChartData, setPerMinuteChartData ] = useState([]);
    const [ perMinuteChartLabels, setTodayChartLabels ] = useState([]);
    const hourlySurveyResultUrl = `${serverAddr}/api/survey_results_last_hour/`;

    const prepareTodayData = useCallback((currentLinkid, authToken, dataArr) => {
        console.log('TrafficSpeed() -> prepareTodayData callback ');
        // setPerMinuteChartData([]);
        let sepedaMotorCounts = 0; 
        let mobilPenumpangCounts = 0; 
        let kendSedangCounts = 0; 
        let busBesarCounts = 0; 
        let trukBesarCounts = 0; 

        if (dataArr.length > 0){
            console.log('TrafficSpeed() -> prepareTodayData callback. Data length = ', dataArr.length);
            for (let i = 0; i < dataArr.length; i += 1) {
                sepedaMotorCounts += dataArr[i].num_veh1;
                mobilPenumpangCounts += (dataArr[i].num_veh2 + dataArr[i].num_veh3 + dataArr[i].num_veh4);
                kendSedangCounts += dataArr[i].num_veh5a; 
                busBesarCounts += (dataArr[i].num_veh5b + dataArr[i].num_veh6a + dataArr[i].num_veh6b) ;
                trukBesarCounts += (dataArr[i].num_veh7a + dataArr[i].num_veh7b + dataArr[i].num_veh7c);
                
                console.log('DEBUG: TrafficSpeed() -> prepareTodayData callback dataArr[', i, '] = ', dataArr[i]);
            }
            
            const chartItems = [{
                                  label: 'Sepeda Motor',
                                  data: sepedaMotorCounts 
                                }];
                                /* , {
                                  label: 'Mobil Penumpang',
                                  data: mobilPenumpangCounts 
                                },
                                {
                                  label: 'Kendaraan Sedang',
                                  data: kendSedangCounts 
                                }, {
                                  label: 'Bus Besar',
                                  data: busBesarCounts 
                                }, {
                                  label: 'Truk Besar',
                                  data: trukBesarCounts 
                                }];
                                */

            const chartData = [];
            const chartLabels = [];

            for (let i = 0; i < chartItems.length; i += 1) {
                chartLabels.push(chartItems[i].label);
                chartData.push(chartItems[i].data);
            }
            setTodayChartLabels(chartLabels);
            setPerMinuteChartData(chartData);

        } else {
            const chartLabels = [];
            chartLabels.push('Sepeda Motor');
            chartLabels.push('Mobil Penumpang');
            chartLabels.push('Kendaraan Sedang');
            chartLabels.push('Bus Besar');
            chartLabels.push('Truk Besar');
            setTodayChartLabels(chartLabels);
            setPerMinuteChartData([0, 0, 0, 0, 0]);

            console.log('TrafficSpeed() -> prepareTodayData callback. Data length is 0');
        }
    }, []);

  const processHistoricalData = useCallback((currentLinkid, hourlySurveyResultUrl, authToken) => {
        /* Get hourly data starting from midnight in the currentLinkid survey location   */
        axios.get(hourlySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('TrafficSpeed() -> useCallback() GET response stats: ', res.data);
                prepareTodayData(currentLinkid, authToken, res.data);
                // console.log('TrafficSpeed() useCallback() -> allChartData: ', allChartData);
           })
           .catch( err => {
               console.log('TrafficSpeed() -> useCallback(): ERROR: ', err.message);
           });

        }, [prepareTodayData]); 



  useEffect(() => {
    console.log('TrafficSpeed() -> useEffect()');
    processHistoricalData(currentLinkid, hourlySurveyResultUrl, authToken);
  }, [currentLinkid, hourlySurveyResultUrl, authToken, processHistoricalData]);

 const chartOptions = useChart({
    series: [10],
    chart: {
                type: 'radialBar',
                toolbar: {
                  show: false 
                }
           },
    plotOptions: { 
                    radialBar: {
                        offsetY: 0,
                        /* startAngle: 0,
                        endAngle: 270, */ 
                        hollow: {
                          margin: 0,
                          size: '70%',
                          background: '#fff',
                          image: undefined,
                          imageOffsetX: 0,
                          imageOffsetY: 0,
                          position: 'front',
                          dropShadow: {
                            enabled: true,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.24
                          }
                        }, 
                        track: {
                          background: '#fff',
                          strokeWidth: '67%',
                          margin: 0, // margin is in pixels
                          dropShadow: {
                            enabled: true,
                            top: -3,
                            left: 0,
                            blur: 4,
                            opacity: 0.35
                          }
                        },
                        dataLabels: {
                          show: true,  
                          name: {
                            offsetY: -10,
                            show: true,
                            color: '#888',
                            fontSize: '17px',
                          },
                          value: {
                            formatter(val) {
                              return val;
                            },
                            color: '#111',
                            fontSize: '36px',
                            show: false,
                          },
                          total: {
                              show: true,
                              label: ['label1', 2],
                              fontSize: '18px',
                            },
                        }
                    }
                },
   fill: {
     type: 'gradient',
     gradient: {
       shade: 'dark',
       type: 'horizontal',
       shadeIntensity: 0.5,
       gradientToColors: ['#ABE5A1'],
       inverseColors: true,
       opacityFrom: 1,
       opacityTo: 1,
       stops: [0, 100]
     }
   },
   stroke: {
     lineCap: 'round'
   },
    legend: {
      show: false,
    }, 
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
            show: false
        }
      }
    }],
  });

  const chartParams = [ {name: 'Sepeda Motor', count: 54}, {name: 'Mobil Penumpang', count: 12},
                        {name: 'Kendaraan Sedang', count: 2}, {name: 'Bus Besar', count: 8},
                        {name: 'Truk Besar', count: 5}]; 

  chartOptions.plotOptions.radialBar.dataLabels.total.label = [chartParams[0].name, `${chartParams[0].count} km/jam`];
  const options = [];  
    for (let i = 1; i < chartParams.length; i += 1) {
        const opts = cloneDeep(chartOptions);
        opts.series = [chartParams[i].count];
        opts.plotOptions.radialBar.dataLabels.total.label = [chartParams[i].name, `${chartParams[i].count} km/jam`];
        options.push(opts);
    }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

        <Grid container justifyContent="center" alignItems="center" spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography align="center" gutterTop variant="h5" component="h4"> 
                      Normal 
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={chartOptions.series} options={chartOptions} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[0].series} options={options[0]} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[1].series} options={options[1]} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[2].series} options={options[2]} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[3].series} options={options[3]} height={300} />
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Divider flexItem />
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography align="center" gutterBottom variant="h5" component="h4"> 
                      Opposite 
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={chartOptions.series} options={chartOptions} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[0].series} options={options[0]} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[1].series} options={options[1]} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[2].series} options={options[2]} height={300} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  <ReactApexChart type="radialBar" series={options[3].series} options={options[3]} height={300} />
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Card>
  );
}
