import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
} from '@mui/material';
// components
import MapWrapper from '../components/map';


export default function MapPage() {

  return (
    <>
      <Helmet>
        <title>Peta Lokasi Survey </title>
      </Helmet>
     
       <Container sx={{marginRight:80,width:500 , height:500}}>
           <Typography variant="h4" gutterBottom>
             Peta Lokasi Survey 
           </Typography>
            <Box sx={{width: 1000, height: 400 }} >
                <MapWrapper />
            </Box>
       </Container>
    </>
  );
}
