// youtube stream player
import { React, useState, useCallback, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player/youtube'
// import axios from 'axios';

import { Helmet } from 'react-helmet-async';
// import { faker } from '@faker-js/faker';
// @mui
// import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardHeader,
  Box,
//  Stack,
  Typography
} from '@mui/material';
// components
// import Iconify from '../components/iconify';
import MapWrapper from '../components/map';

// sections
import {
//  AppTasks,
//  AppNewsUpdate,
//  AppOrderTimeline,
//  AppCurrentVisits,
//  AppWebsiteVisits,
//  AppTrafficBySite,
//  AppWidgetSummary,
//  AppCurrentSubject,
//  AppConversionRates,
//  TrafficInDay,
  TrafficLast5Min,
  TrafficLastHour,
  TrafficComparison,
  TrafficToday,
  TrafficPerMinute,
  TrafficSpeed,
//  TestChart
} from '../sections/@dashboard/app';

// import useFiveMinStat from '../hooks/useBackendApi'; 

function getLocations() {
  /* TODO: Read the locations data from the backend DB here  */
  const locations = [{linkid: "22037", position:[-6.9384317, 107.7351057], 
                      vidstream: "https://youtu.be/JPnN3czZWtk" , road: "JL. CIBIRU (BANDUNG)", city: "KOTA BANDUNG"},
                     {linkid: "22033", position:[-6.8514286, 107.4974082], 
                      vidstream:"https://youtu.be/cUScnWYoSPY" , road: "BTS. KOTA PADALARANG - BTS. KOTA BANDUNG", city: "BANDUNG BARAT"}, 
                     {linkid: "22038", position:[-6.9645626, 107.7972773], 
                      vidstream:"https://youtu.be/f95c4S2F9-U" , road: "NAGREG - BTS. KAB. BANDUNG/GARUT", city: "KAB. BANDUNG"}]; 
  return locations;
}

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  // const theme = useTheme();
  const [ currentLinkid, setCurrentLinkid ] = useState("22038");
  const [ currentVidStreamUrl, setCurrentVidStreamUrl ] = useState("https://youtu.be/f95c4S2F9-U"); // use site 22038 stream URL
  const locations = useRef([]); 

  const vidStreamTitle = `Perhitungan Kendaraan - Ruas ${currentLinkid}`;
  const last5MinDataTitle = `Volume Kendaraan (kend./5 menit) - Ruas ${currentLinkid}`;
  const perMinuteStatTitle = `Volume Kendaraan (kend./menit) - Ruas ${currentLinkid}`;
  const hourlyStatTitle = `Volume Kendaraan Harian (kend./jam) - Ruas ${currentLinkid}`;
  const todayStatTitle = `Total Volume Kendaraan Harian (kend./hari) - Ruas ${currentLinkid}`;
  const comparisonStatTitle = `Perbandingan Volume Kendaraan (kend./jam) - Ruas ${currentLinkid}`;
  const speedStatTitle = `Kecepatan Kendaraan (km/jam) - Ruas ${currentLinkid}`;
//  const dailyStatTitle = `Total Volume Kendaraan Harian - Ruas ${currentLinkid}`;
  const today = new Date();
  const date = `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}`;
  const curTime = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const curDateTime = `${date} | ${curTime} `;

  // const serverAddr = '127.0.0.1:8000';
  const serverAddr = 'https://7f48-2001-448a-3030-3a6a-f91c-386-8714-3b23.ngrok-free.app';
  // const url = `http://${serverAddr}/api/test`;
  // const surveyResultUrl = `http://${serverAddr}/api/survey_results/`;
  const authToken = 'Token c2a2ca6f5e3f0fdf7f1861d71f72829f7888c5b2';

  locations.current = getLocations();

  useEffect(() => {
    console.log("DashboardAppPage() 1st useEffect() currentLinkid = ", currentLinkid); 
  }, [currentLinkid]);

  const updateDashboard = useCallback((newLinkid) => {
        console.log("DashboardAppPage() Updating dashboard with new linkid: ", newLinkid);
        if (newLinkid !== currentLinkid) {
            setCurrentLinkid(newLinkid);
            console.log("DashboardAppPage() inside if: currentLinkid: ", currentLinkid);
            for (let i = 0; i < locations.current.length; i += 1) {
                if (locations.current[i].linkid === newLinkid) {
                    setCurrentVidStreamUrl(locations.current[i].vidstream);
                    break;
                }
            }
        }
    }, [currentLinkid]);


  return (
    <>
      <Helmet>
        <title> APACE - Dashboard </title>
      </Helmet>

      { /* <Container maxWidth="xl">  */}
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            { /* 
            <CardHeader title={"Peta Lokasi Survei"} subheader={"Klik pada marker untuk informasi detail"} align="center" />
              */ }
            <Typography align="center" gutterBottom variant="h5" component="h2">
              <br />
              Peta Lokasi Survei
            </Typography>
            <Typography align="center" gutterBottom variant="body2">
              Klik pada <i>marker</i> untuk melihat lebih detail dan menampilkan data di dashboard
            </Typography>
            <MapWrapper updateDashboard={updateDashboard} currentLinkid={currentLinkid} locations={locations.current} />
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Card>
            {/* TODO: Title no.ruas diambil dari query ke DB untuk lokasi yg dipilih saat ini  */}
            <CardHeader title={vidStreamTitle} subheader={curDateTime} align="left" />
            <Box justifyContent="center" alignItems="center" sx={{ p: 3, pb: 3 }}>
              <ReactPlayer url={currentVidStreamUrl} volume={0.1} muted playing width='100%' /* height='100%' */ />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TrafficLast5Min title={last5MinDataTitle}
            subheader={curDateTime} /* TODO: Modify this to latest DB item curDateTime and time - query via Axios  */
            currentLinkid={currentLinkid} 
            serverAddr={serverAddr}
            authToken={authToken} />
        </Grid>
      </Grid>
    
      <Grid item xs={12} md={6} lg={6} marginTop={3}>
        <TrafficPerMinute title={perMinuteStatTitle}
          subheader={curDateTime}
          currentLinkid={currentLinkid}
          serverAddr={serverAddr}
          authToken={authToken} />
      </Grid>

      <Grid item xs={12} md={6} lg={6} marginTop={3}>
        <TrafficLastHour title={hourlyStatTitle}
          subheader={curDateTime}
          currentLinkid={currentLinkid}
          serverAddr={serverAddr}
          authToken={authToken} />
      </Grid>

      <Grid item xs={12} md={6} lg={6} marginTop={3}>
        <TrafficToday title={todayStatTitle}
          subheader={curDateTime}
          currentLinkid={currentLinkid}
          serverAddr={serverAddr}
          authToken={authToken} />
      </Grid>

      <Grid item xs={12} md={6} lg={6} marginTop={3}>
        <TrafficComparison title={comparisonStatTitle}
          subheader={curDateTime}
          currentLinkid={currentLinkid}
          serverAddr={serverAddr}
          authToken={authToken} />
      </Grid>

    <Grid item xs={12} md={6} lg={6} marginTop={3}>
        <TrafficSpeed title={speedStatTitle}
          subheader={curDateTime}
          currentLinkid={currentLinkid}
          serverAddr={serverAddr}
          authToken={authToken} />
      </Grid>

     { /*
      <Grid item xs={12} md={6} lg={8} marginTop={3}>
        <AppWebsiteVisits
          title={dailyStatTitle}
          subheader={curDateTime}
          chartLabels={[
            '05/11/2023',
            '05/12/2023',
            '05/13/2023',
            '05/14/2023',
            '05/15/2023',
            '05/16/2023',
            '05/17/2023',
            '05/18/2023',
            '05/19/2023',
            '05/20/2023',
            '05/21/2023',
          ]}
          chartData={[
            {
              name: 'Minggu Berjalan',
              type: 'column',
              fill: 'solid',
              data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
            },
            {
              name: 'Minggu Lalu',
              type: 'area',
              fill: 'gradient',
              data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
            },
            {
              name: '2 Minggu Lalu',
              type: 'line',
              fill: 'solid',
              data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
            },
          ]}
        />
      </Grid>
        */ }

      { /* </Container>  */}

    </>
  );
}
