import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

TrafficToday.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  currentLinkid: PropTypes.string.isRequired,
  serverAddr: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default function TrafficToday({ title, subheader, currentLinkid, serverAddr, authToken, ...other }) {
    const [ todayChartData, setTodayChartData ] = useState([]);
    const [ todayChartLabels, setTodayChartLabels ] = useState([]);
    const hourlySurveyResultUrl = `${serverAddr}/api/survey_results_last_hour/`;

    const prepareTodayData = useCallback((currentLinkid, authToken, dataArr) => {
        console.log('TrafficToday() -> prepareTodayData callback ');
        // setTodayChartData([]);
        let sepedaMotorCounts = 0; 
        let mobilPenumpangCounts = 0; 
        let kendSedangCounts = 0; 
        let busBesarCounts = 0; 
        let trukBesarCounts = 0; 

        if (dataArr.length > 0){
            console.log('TrafficToday() -> prepareTodayData callback. Data length = ', dataArr.length);
            for (let i = 0; i < dataArr.length; i += 1) {
                sepedaMotorCounts += dataArr[i].num_veh1;
                mobilPenumpangCounts += (dataArr[i].num_veh2 + dataArr[i].num_veh3 + dataArr[i].num_veh4);
                kendSedangCounts += dataArr[i].num_veh5a; 
                busBesarCounts += (dataArr[i].num_veh5b + dataArr[i].num_veh6a + dataArr[i].num_veh6b) ;
                trukBesarCounts += (dataArr[i].num_veh7a + dataArr[i].num_veh7b + dataArr[i].num_veh7c);
                
                console.log('DEBUG: TrafficToday() -> prepareTodayData callback dataArr[', i, '] = ', dataArr[i]);
            }
            
            const chartItems = [{
                                  label: 'Sepeda Motor',
                                  data: sepedaMotorCounts 
                                }, {
                                  label: 'Mobil Penumpang',
                                  data: mobilPenumpangCounts 
                                },
                                {
                                  label: 'Kendaraan Sedang',
                                  data: kendSedangCounts 
                                }, {
                                  label: 'Bus Besar',
                                  data: busBesarCounts 
                                }, {
                                  label: 'Truk Besar',
                                  data: trukBesarCounts 
                                }];

            const chartData = [];
            const chartLabels = [];

            for (let i = 0; i < chartItems.length; i += 1) {
                chartLabels.push(chartItems[i].label);
                chartData.push(chartItems[i].data);
            }
            setTodayChartLabels(chartLabels);
            setTodayChartData(chartData);

        } else {
            const chartLabels = [];
            chartLabels.push('Sepeda Motor');
            chartLabels.push('Mobil Penumpang');
            chartLabels.push('Kendaraan Sedang');
            chartLabels.push('Bus Besar');
            chartLabels.push('Truk Besar');
            setTodayChartLabels(chartLabels);
            setTodayChartData([0, 0, 0, 0, 0]);

            console.log('TrafficToday() -> prepareTodayData callback. Data length is 0');
        }
    }, []);

  const processHistoricalData = useCallback((currentLinkid, hourlySurveyResultUrl, authToken) => {
        /* Get hourly data starting from midnight in the currentLinkid survey location   */
        axios.get(hourlySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('TrafficToday() -> useCallback() GET response stats: ', res.data);
                prepareTodayData(currentLinkid, authToken, res.data);
                // console.log('TrafficToday() useCallback() -> allChartData: ', allChartData);
           })
           .catch( err => {
               console.log('TrafficToday() -> useCallback(): ERROR: ', err.message);
           });

        }, [prepareTodayData]); 



  useEffect(() => {
    console.log('TrafficToday() -> useEffect()');
    processHistoricalData(currentLinkid, hourlySurveyResultUrl, authToken);
  }, [currentLinkid, hourlySurveyResultUrl, authToken, processHistoricalData]);

 const totalVhc = useCallback((chartData) => {
        let total = 0;
        for (let i = 0; i < chartData.length; i += 1) {
            total += chartData[i];
        } 
        return total;
    }, []); 
 
 const chartOptions = useChart({
    plotOptions: { 
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                          margin: 5,
                          size: '30%',
                          background: 'transparent',
                          image: undefined,
                        },
                        dataLabels: {
                          show: true,
                          name: {
                            show: false,
                          },
                          value: {
                            show: true,
                            formatter(val) {
                              let percent = (val / totalVhc(todayChartData)) * 100;
                              percent = Math.round((percent + Number.EPSILON) * 100) / 100; 
                              return `${percent} %` ;
                            },
                          },
                          total: {
                            show: true,
                            label: 'Total',
                            formatter (w) {
                              const total = totalVhc(todayChartData);
                              return `Total: ${total}`;
                            }
                          }
                        }
                    } 
                },

    // colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5', '#07b71a'],
    labels: todayChartLabels,
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: false,
      },
      markers: {
        size: 0
      },
      formatter (seriesName, opts) {
        let percent = (opts.w.globals.series[opts.seriesIndex] / totalVhc(todayChartData)) * 100;
        percent = Math.round((percent + Number.EPSILON) * 100) / 100; 
        return `${seriesName} :  ${opts.w.globals.series[opts.seriesIndex]} -- [${percent}%]`;
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
            show: false 
        }
      }
    }],
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 2, pb: 1 }} dir="ltr">
        <ReactApexChart type="radialBar" series={todayChartData} options={chartOptions} height={480} />
      </Box>
    </Card>
  );
}
