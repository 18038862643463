import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

TrafficLastHour.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  currentLinkid: PropTypes.string.isRequired,
  serverAddr: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,

//  chartData: PropTypes.array.isRequired,
//  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// export default function TrafficLastHour({ title, subheader, chartLabels, chartData, ...other }) {
export default function TrafficLastHour({ title, subheader, currentLinkid, serverAddr, authToken, ...other }) {
    const [ lastHourData, setLastHourData ] = useState([]);
    const chartLabels=[ '00-01', '01-02', '02-03', '03-04', '04-05', '05-06', 
                        '06-07', '07-08', '08-09', '09-10', '10-11', '11-12',
                        '12-13', '13-14', '14-15', '15-16', '16-17', '17-18',
                        '18-19', '19-20', '20-21', '21-22', '22-23', '23-24', ];

    const hourlySurveyResultUrl = `${serverAddr}/api/survey_results_last_hour/`;
    const prevDaySurveyResultUrl = `${serverAddr}/api/survey_results_prev_day/`;

    /*
    const last7DaySurveyResultUrl = `${serverAddr}/api/survey_results_last_7day/`;


    const append7DayAvgData = useCallback((currentLinkid, last7DaySurveyResultUrl, authToken, chartData) => {
        axios.get(last7DaySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('append7DayAvgData() -> useCallback() GET response stats: ', res.data);
                console.log('append7DayAvgData() -> useCallback(): chartData', chartData);
                // finalizeChartData(currentLinkid, authToken, res.data, chartData);
           })
           .catch( err => {
               console.log('append7DayAvgData() -> useCallback(): ERROR: ', err.message);
           });

        }, []); 

    const updateChartData = useCallback((currentLinkid, authToken, prevDayData, chartData) => {
            if (prevDayData.length > 0) {
                console.log('updateChartData() -> useCallback(): chartData', chartData);
                const prevDayTotalCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 

                for (let i = 0; i < prevDayData.length; i += 1) {
                    // Remove zone indicator to prevent timezone conversion by the browser
                    const [dateStr, zone] = prevDayData[i].datetime_utc.split('Z'); 
                    const itemDate = new Date(dateStr);
                    const itemHour = itemDate.getHours();
                    
                    for (let j = 0; j < 24; j += 1) {
                        if (itemHour === j) {
                            prevDayTotalCounts[j] += (prevDayData[i].num_veh1 + prevDayData[i].num_veh2 + prevDayData[i].num_veh3 + 
                                                    prevDayData[i].num_veh4 + prevDayData[i].num_veh5a + prevDayData[i].num_veh5b + 
                                                    prevDayData[i].num_veh6a + prevDayData[i].num_veh6b + prevDayData[i].num_veh7a + 
                                                    prevDayData[i].num_veh7b + prevDayData[i].num_veh7c);
                        }
                    }
                }
               
                const prevDayTotal = {
                                        name: 'Total Hari Kemarin',
                                        type: 'line',
                                        fill: 'fill',
                                        data: prevDayTotalCounts 
                                      };
 
                const appendedChartData = [];
                for (let i = 0; i < chartData.length; i+= 1) {
                    appendedChartData.push(chartData[i]);
                }
                appendedChartData.push(prevDayTotal);
                console.log("updateChartData() -> useCallback(): appendedChartData = ", appendedChartData);
    
                setLastHourData(appendedChartData);

            } else {
                console.log('updateChartData() -> useCallback(): prevDayData.length is 0');
                setLastHourData(chartData);
            }
        }, []);

    const appendPrevDayData = useCallback((currentLinkid, prevDaySurveyResultUrl, authToken, chartData) => {
        axios.get(prevDaySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('appendPrevDayData() -> useCallback() GET response stats: ', res.data);
                console.log('appendPrevDayData() -> useCallback(): chartData', chartData);
                updateChartData(currentLinkid, authToken, res.data, chartData);
           })
           .catch( err => {
               console.log('appendPrevDayData() -> useCallback(): ERROR: ', err.message);
           });

        }, [updateChartData]); 

    */

    const updateLastHourData = useCallback((currentLinkid, authToken, dataArr) => {
        console.log('TrafficLastHour() -> updateLastHourData callback ');
        // setLastHourData([]);
        const sepedaMotorCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const mobilPenumpangCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const kendSedangCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const busBesarCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 
        const trukBesarCounts = [0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0, 
                                    0, 0, 0, 0, 0, 0, 0, 0]; 

        if (dataArr.length > 0){
            console.log('TrafficLastHour() -> updateLastHourData callback. Data length = ', dataArr.length);
            for (let i = 0; i < dataArr.length; i += 1) {
                // Remove zone indicator to prevent timezone conversion by the browser
                const [dateStr, zone] = dataArr[i].datetime_utc.split('Z'); 
                const itemDate = new Date(dateStr);
                const itemHour = itemDate.getHours();
                
                for (let j = 0; j < 24; j += 1) {
                    if (itemHour === j) {
                        sepedaMotorCounts[j] += dataArr[i].num_veh1;
                        mobilPenumpangCounts[j] += (dataArr[i].num_veh2 + dataArr[i].num_veh3 + dataArr[i].num_veh4);
                        kendSedangCounts[j] += dataArr[i].num_veh5a; 
                        busBesarCounts[j] += (dataArr[i].num_veh5b + dataArr[i].num_veh6a + dataArr[i].num_veh6b) ;
                        trukBesarCounts[j] += (dataArr[i].num_veh7a + dataArr[i].num_veh7b + dataArr[i].num_veh7c);
                    } 
                }
                
                console.log('DEBUG: TrafficLastHour() -> updateLastHourData callback dataArr[', i, '] = ', dataArr[i]);
                console.log('DEBUG: TrafficLastHour() -> updateLastHourData callback itemDate  = ', itemDate);
                console.log('DEBUG: TrafficLastHour() -> updateLastHourData callback dateStr = ', dateStr);
            }
            const vehicleCounts = [{
                                      name: 'Sepeda Motor',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: sepedaMotorCounts 
                                    }, {
                                      name: 'Mobil Penumpang',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: mobilPenumpangCounts 
                                    },
                                    {
                                      name: 'Kendaraan Sedang',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: kendSedangCounts 
                                    }, {
                                      name: 'Bus Besar',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: busBesarCounts 
                                    }, {
                                      name: 'Truk Besar',
                                      type: 'bar',
                                      fill: 'solid',
                                      data: trukBesarCounts 
                                    }];
            // appendPrevDayData(currentLinkid, prevDaySurveyResultUrl, authToken, vehicleCounts);
            setLastHourData(vehicleCounts);
            
        } else {
            setLastHourData([{
                              name: 'Sepeda Motor',
                              type: 'bar',
                              fill: 'solid',
                              data: sepedaMotorCounts 
                            }, {
                              name: 'Mobil Penumpang',
                              type: 'bar',
                              fill: 'solid',
                              data: mobilPenumpangCounts 
                            },
                            {
                              name: 'Kendaraan Sedang',
                              type: 'bar',
                              fill: 'solid',
                              data: kendSedangCounts 
                            }, {
                              name: 'Bus Besar',
                              type: 'bar',
                              fill: 'solid',
                              data: busBesarCounts 
                            }, {
                              name: 'Truk Besar',
                              type: 'bar',
                              fill: 'solid',
                              data: trukBesarCounts 
                            } , {
                              name: 'Total Hari Kemarin',
                              type: 'line',
                              fill: 'fill',
                              data: [80, 90, 79, 79, 98, 96, 94, 96, 
                                    96, 96, 97, 13, 14, 97, 57, 57, 
                                    57, 65, 46, 47, 49, 51, 53]
                            }, {
                              name: 'Rata-rata Pekan Lalu',
                              type: 'line',
                              fill: 'fill',
                              data: [14, 25, 25, 46, 51, 71, 61, 51, 
                                     41, 42, 52, 62, 82, 92, 43, 93, 
                                     93, 83, 64, 74, 74, 84, 55, 45]
                            }]);

            console.log('TrafficLastHour() -> updateLastHourData callback. Data length is 0');
        }
    }, [ /* appendPrevDayData, prevDaySurveyResultUrl */ ]);

  const processLastHourData = useCallback((currentLinkid, hourlySurveyResultUrl, authToken) => {
        axios.get(hourlySurveyResultUrl, {
               headers: { 'Authorization': authToken },
               params: { locid: currentLinkid, latest: 'true' } 
           })
           .then(res => {
                console.log('Status Code: ', res.status);
                console.log('TrafficLastHour() -> useCallback() GET response stats: ', res.data);
                updateLastHourData(currentLinkid, authToken, res.data);
                // console.log('TrafficLastHour() useCallback() -> lastHourData: ', lastHourData);
           })
           .catch( err => {
               console.log('TrafficLastHour() -> useCallback(): ERROR: ', err.message);
           });

        }, [updateLastHourData]); 

  useEffect(() => {
    console.log('TrafficLastHour() -> useEffect()');
    processLastHourData(currentLinkid, hourlySurveyResultUrl, authToken);
  }, [currentLinkid, hourlySurveyResultUrl, authToken, processLastHourData]);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '80%' } },
    /* fill: { type: chartData.map((i) => i.fill) }, */
    fill: { type: lastHourData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'categories' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} kendaraan`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 2, pb: 1 }} dir="ltr">
        {/* <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} /> */}
        <ReactApexChart type="line" series={lastHourData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
