import PropTypes from 'prop-types';
import { 
    MapContainer, 
    TileLayer, 
    useMap, 
    Marker, 
    Popup, 
    // Tooltip
} from 'react-leaflet';

// import { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Icon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import tileLayer from './util/tileLayer';
import 'leaflet/dist/leaflet.css';

// css
import './Map.css';

const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  map.setView([lat, lng]);
}

function CreateMarkerIcon(normal) {
    let markerIcon = null;
    if (normal === true) {
        markerIcon = new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] });
    } else {
        markerIcon = new Icon({ iconUrl: markerIconPng, iconSize: [36, 60], iconAnchor: [18, 60] });
    }

    return markerIcon;
}

ClickableMarker.propTypes = {
    loc: PropTypes.object,
    updateDashboard: PropTypes.func,
};
function ClickableMarker(props) {

  // const loc = props.loc;
  const { loc, updateDashboard } = props;
  // lokasi survey
  // const surveyCenter = [-6.9645626, 107.7972773];

  // const [buttonText, setButtonText] = useState('Show Data in Dashboard');
  const buttonText = 'Show Data in Dashboard';

  function handleClick() {
    // setButtonText('New text');
    // alert("Send Data to update dashboard state to backend");
    console.log("Send Data to update dashboard state to backend to: ", loc.linkid);
    console.log("ClickableMarker() props: ", props);
    // props.updateDashboard(loc.linkid); 
    updateDashboard(loc.linkid); 
  }

  return (
    <Marker
        position={[loc.position[0], loc.position[1]]} 
        icon={CreateMarkerIcon(true)} id={loc.linkid}>
      <Popup>
        <b><h3>{loc.linkid}</h3></b>
        <i>{loc.road}</i>
        <br/><br />
        {loc.city}
        <br/><br />
        <button type="button" onClick={handleClick}>{buttonText}</button>
      </Popup>
    </Marker>
  )
}


GenClickableMarkers.propTypes = {
    locationsArr: PropTypes.array,
    updateDashboard: PropTypes.func,
};
function GenClickableMarkers(props) {
    return props.locationsArr.map((loc) => (
                        <>
                            console.log("GenClickableMarkers() props: ", props);
                            <ClickableMarker loc={loc} updateDashboard={props.updateDashboard} />
                        </>
                        )); // The last parentheses is an implicit return from function
}

/*
 params contains 2 fields:
 - currentLinkid 
 - locations
 see src/pages/DashboardAppPage.js
 locations is an array like so:

 const locations = [{linkid: "22023", position:[-6.9645626, 107.7972773], desc: "BTS. KOTA CILEUNYI - NAGREG"},
                     {linkid: "22033", position:[-6.8514286, 107.4974082], desc: "Jl. Raya Padalarang"}, 
                     {linkid: "22038", position:[-6.9384317, 107.7351057], desc: "Jl. Cileunyi"}]; 
 */
MapWrapper.propTypes = {
    currentLinkid: PropTypes.string,
    locations: PropTypes.array,
    updateDashboard: PropTypes.func,
};
// const MapWrapper = (props) => {
export default function MapWrapper(props) { 
  // lokasi survey
  const surveyCenter = [-6.9645626, 107.7972773];

  // Indonesia map center (Balikpapan)
  // const indoCenter = [-1.1746027, 116.8005487];

  // const linkid = "22023"; 
  // const locations = [{linkid: "22023", position:[-6.9645626, 107.7972773], desc: "BTS. KOTA CILEUNYI - NAGREG"},
  //                    {linkid: "22033", position:[-6.8514286, 107.4974082], desc: "Jl. Raya Padalarang"}, 
  //                    {linkid: "22038", position:[-6.9384317, 107.7351057], desc: "Jl. Cileunyi"}]; 
  console.log("props.currentLinkid: ", props.currentLinkid);
  console.log("props.locations: ", props.locations);

  return (
    <MapContainer center={surveyCenter} zoom={9} /* scrollWheelZoom={true} */ >
      <TileLayer {...tileLayer} />
      <RecenterAutomatically lat={surveyCenter[0]} lng={surveyCenter[1]} />
      { /*
      <RecenterAutomatically lat={indoCenter[0]} lng={indoCenter[1]} />
      <Marker position={[surveyCenter[0], surveyCenter[1]]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
        <Popup>
            <Link to="/dashboard/app">Ruas 22023, Jawa Barat <br /><i>(klik untuk ke dashboard)</i></Link> 
        </Popup>
        <Tooltip>
            <i>No. Ruas      : 22023 </i><br />
            <i>Nama Ruas     : BTS. KOTA CILEUNYI - NAGREG </i><br />
            <i>Koordinat GPS : -6.964265065198158, 107.7998717231688 </i>
        </Tooltip>
       </Marker>
      <ClickableMarker loc={locations[0]} />
      <GenClickableMarkers locationsArr={locations} /> 
       */ }
      <GenClickableMarkers updateDashboard={props.updateDashboard} locationsArr={props.locations} /> 
    </MapContainer>
  );
}

// export default MapWrapper;
